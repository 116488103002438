import React from "react";
import style from "../ImageOnlyM.css";
import TextOnly from "../../../../media/text-only.png";
const ImageOnlyS3M = () => {
  return (
    <div className={"ImageOnlyM lg:px-[90px] lg:py-[120px] overflow-hidden"}>
      <div
        className={`max-w-max mx-auto relative flex justify-end items-center mt-20 h-[590px] lg:h-[700px] rounded-16 `}
      >
        <img
          className={"w-full h-full absolute inset-0  object-cover"}
          src={TextOnly}
          alt=""
        />
        <div
          className={`h-full z-10 flex items-end pb-20 mr-10 lg:mr-24 px-5 lg:px-12 lg:rounded-16 ${style.designBackground}`}
        >
          <h1
            className={
              "flex flex-col text-darkBorder lg:flex-row font-medium text-24 leading-[32px] lg:text-40 lg:leading-[53px] tracking-[0.4em]"
            }
          >
            <span>Beyond</span> <span>Design.</span>{" "}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default ImageOnlyS3M;
