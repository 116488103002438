import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Boheimian from "./pages/boheimian";
import Classic from "./pages/classic";
import Modern from "./pages/modern";
import Home from "./components/Home";
import Layout from "./components/Layout";

function App() {
  return (
    <BrowserRouter >
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="boheimian">
            <Route index element={<Boheimian />} />
          </Route>
          <Route path="classic">
            <Route index element={<Classic />} />
          </Route>
          <Route path="modern">
            <Route index element={<Modern />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
