import React, { useState } from "react";
import CitiesS1M from "../../components/Cities/Modern/Cities-S1-M";
import CitiesS2M from "../../components/Cities/Modern/Cities-S2-M";
import CitiesS3M from "../../components/Cities/Modern/Cities-S3-M";
import ContactUsS1M from "../../components/ContactUs/Modern/ContactUs-S1-M";
import ContactUsS2M from "../../components/ContactUs/Modern/ContactUs-S2-M";
import ContactUsS3M from "../../components/ContactUs/Modern/ContactUs-S3-M";
import FooterS1M from "../../components/Footer/Modern/Footer-S1-M";
import FooterS2M from "../../components/Footer/Modern/Footer-S2-M";
import FooterS3M from "../../components/Footer/Modern/Footer-S3-M";
import HeaderS11M from "../../components/Header/Modern/Header-S1-1-M";
import HeaderS12M from "../../components/Header/Modern/Header-S1-2-M";
import HeaderS13M from "../../components/Header/Modern/Header-S1-3-M";
import HeaderS2M from "../../components/Header/Modern/Header-S2-M";
import HeaderS3M from "../../components/Header/Modern/Header-S3-M";
import HeroCardS1M from "../../components/HeroCard/Modern/HearoCard-S1-M";
import HeroCardS2M from "../../components/HeroCard/Modern/HearoCard-S2-M";
import HeroCardS3M from "../../components/HeroCard/Modern/HearoCard-S3-M";
import ImageOnlyS1M from "../../components/ImageOnly/Modern/ImageOnly-S1-M";
import ImageOnlyS2M from "../../components/ImageOnly/Modern/ImageOnly-S2-M";
import ImageOnlyS3M from "../../components/ImageOnly/Modern/ImageOnly-S3-M";
import InstagramS1M from "../../components/Instagram/Modern/Instagram-S1-M";
import InstagramS2M from "../../components/Instagram/Modern/Instagram-S2-M";
import InstagramS3M from "../../components/Instagram/Modern/Instagram-S3-M";
import TextImageS1M from "../../components/TextImage/Modern/TextImage-S1-M";
import TextImageS2M from "../../components/TextImage/Modern/TextImage-S2-M";
import TextImageS3M from "../../components/TextImage/Modern/TextImage-S3-M";
import TextOnlyS1M from "../../components/TextOnly/Modern/TextOnly-S1-M";
import TextOnlyS2M from "../../components/TextOnly/Modern/TextOnly-S2-M";
import TextOnlyS3M from "../../components/TextOnly/Modern/TextOnly-S3-M";
import Slider from "../../components/Slider";
import { useSearchParams } from "react-router-dom";

const Modern = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [componentMemory, setComponentMemory] = useState({
    city: searchParams.get("city") || "CitiesS1M",
    contactUs: searchParams.get("contactUs") || "ContactUsS1M",
    footer: searchParams.get("footer") || "FooterS1M",
    header: searchParams.get("header") || "HeaderS11M",
    hearoCard: searchParams.get("hearoCard") || "HearoCardS1M",
    instagram: searchParams.get("instagram") || "InstagramS1M",
    textOnly: searchParams.get("textOnly") || "TextOnlyS1M",
    imageOnly: searchParams.get("imageOnly") || "ImageOnlyS1M",
    textImage: searchParams.get("textImage") || "TextImageS1M",
  });

  const updateComponentMemory = (data) => {
    setComponentMemory(data);
    setSearchParams(data);
  };

  return (
    <div className="App flex flex-col gap-20 px-10 pb-10 pt-36">
      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="header"
        title="Header Components Available"
        components={[HeaderS11M, HeaderS12M, HeaderS13M, HeaderS2M, HeaderS3M]}
      />

      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="hearoCard"
        title="Hearo Card Components Available"
        components={[HeroCardS1M, HeroCardS2M, HeroCardS3M]}
      />

      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="textImage"
        title="Text Image Components Available"
        components={[TextImageS1M, TextImageS2M, TextImageS3M]}
      />

      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="city"
        title="City Components Available"
        components={[CitiesS1M, CitiesS2M, CitiesS3M]}
      />

      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="textOnly"
        title="Text Only Components Available"
        components={[TextOnlyS1M, TextOnlyS2M, TextOnlyS3M]}
      />

      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="imageOnly"
        title="Image Only Components Available"
        components={[ImageOnlyS1M, ImageOnlyS2M, ImageOnlyS3M]}
      />

      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="instagram"
        title="Instagram Components Available"
        components={[InstagramS1M, InstagramS2M, InstagramS3M]}
      />

      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="contactUs"
        title="Contact Us Components Available"
        components={[ContactUsS1M, ContactUsS2M, ContactUsS3M]}
      />

      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="footer"
        title="Footer Components Available"
        components={[FooterS1M, FooterS2M, FooterS3M]}
      />
    </div>
  );
};

export default Modern;
