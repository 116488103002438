import React from "react";
import "../HeroCardM.css";
import arrowLeft from "../../../../images/icons/arrow-up-small.svg";
import arrowRight from "../../../../images/icons/arrow-up-small2.svg";
import arrowDown from "../../../../images/icons/Arrow-Down Square blue.svg";
const HearoCardS2M = () => {
  return (
    <div className=" HeroCardM w-full max-w-max mx-auto lg:py-[160px]">
      <section className={"cardSection"}>
        <div className="mt-16">
          <div className="flex items-center justify-center ">
            <button className={`arrowBtn mr-10 lg:mr-5`}>
              <img src={arrowLeft} alt="arrow" />
            </button>
            <h3
              className={`font-bold text-[24px] leading-[28px] lg:text-[40px] lg:leading-[46px] px-4 bg-[#fff]`}
            >
              Sold Properties
            </h3>
            <button className={`arrowBtn ml-10 lg:ml-5`}>
              <img src={arrowRight} alt="arrow" />
            </button>
          </div>
        </div>
        <div
          className={`flex flex-col h-[400px] lg:h-[550px] lg:flex-row lg:justify-end items-center mt-10 rounded-16 lg:p-5 backgroundSectionFirst`}
        >
          <div
            className={`p-4 lg:p-10 mt-60 lg:mt-0 w-full lg:w-auto lg:rounded-16 flex flex-col items-center content`}
          >
            <h4 className="font-bold text-[24px] leading-[24px] lg:text-[36px] lg:leading-[41px]">
              11 5152 Canada Way, Burnaby
            </h4>
            <h5 className="text-[18px] leading-[26px] lg:text-[24px] lg:leading-[35px]">
              Keller Williams Realty VanCentral
            </h5>
            <div className={`priceTag  mt-4`}>
              <span className="font-medium text-[32px] leading-[29px] lg:text-[40px] lg:leading-[37px]">
                $ 317,000
              </span>
              <span className="flex items-center">
                <span className={"off"}>20%</span>
                <img src={arrowDown} alt="arrow down" />
              </span>
            </div>
            <div className={"line"}></div>
            <p className="text-[20px] leading-[24px] lg:text-[24px] lg:leading-[29px] tracking-wider">
              3 Beds | 1 Bath | 416 Sqft
            </p>
            <p
              className={`text-[18px] leading-[22px] lg:text-[24px] lg:leading-[29px] type`}
            >
              <span>Type </span>
              Single Family
              <span className="ml-8">Land Size </span>
              5,110,416 sqft
            </p>
            <a href="/" className={"listing"}>
              VIEW LISTING
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HearoCardS2M;
