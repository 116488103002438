import React from "react";
import "../TextOnlyC.css";
const TextOnlyS3C = () => {
  return (
    <div className={`lg:py-[120px] textonly-c `}>
      <div className="w-full text-center mb-16 py-11 px-8 lg:px-0">
        <div className="max-w-max mx-auto">
          <p className={"text-16 leading-[21px]"}>About</p>
          <div
            className={"border-1 border-grayBorder w-4/5 lg:w-4/12 m-auto my-6"}
          ></div>
          <p className={"lg:w-7/12 m-auto text-14 leading-[19px]"}>
            Lorem ipsum dolor sit amet consectetur. Fringilla maecenas diam
            aenean duis sit nunc id integer sed. Egestas dictum elementum massa
            gravida fusce ullamcorper nisl ut non. Viverra et lectus velit
            interdum lectus arcu feugiat. Leo enim odio ut imperdiet eget. Dui
            nunc nec in at amet. Enim tortor diam etiam quam. Amet nullam vel
            faucibus quisque aliquam quis cursus. Purus. Lorem ipsum dolor sit
            amet consectetur. Fringilla maecenas diam aenean duis sit nunc id
            integer sed. Egestas dictum elementum massa gravida fusce
            ullamcorper nisl ut non. Viverra et lectus velit interdum lectus
            arcu feugiat. Leo enim odio ut imperdiet eget. Dui nunc nec in at
            amet. Enim tortor diam etiam quam. Amet nullam vel faucibus quisque
            aliquam quis cursus. Purus.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TextOnlyS3C;
