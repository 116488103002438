import React from "react";
import "../ImageOnlyM.css";
import TextOnly from "../../../../media/text-only.png";
const ImageOnlyS2M = () => {
  return (
    <div className={"ImageOnlyM lg:px-[90px] lg:py-[120px] overflow-hidden"}>
      <div className={"max-w-max mx-auto relative mt-20 h-[550px] "}>
        <div
          className={`absolute w-full h-full lg:w-[70%] h-full flex z-10 items-end p-12 rounded-16 `}
        >
          <h1
            className={
              "font-medium text-darkBorder text-24 leading-[32px] lg:text-40 lg:leading-[53px] tracking-[0.4em]"
            }
          >
            Beyond Design.
          </h1>
        </div>
        <div
          className={
            "absolute w-full h-[110%] bg-magnolia -top-6 inset-x-1/3 rounded-16"
          }
        ></div>
        <img
          className={"w-full h-full absolute inset-0  object-cover"}
          src={TextOnly}
          alt=""
        />
      </div>
    </div>
  );
};

export default ImageOnlyS2M;
