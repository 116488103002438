import React from "react";
import "../ContactUsC.css";
const ContactUsS2C = () => {
  return (
    <div className="ContactUsC  lg:py-[120px]">
      <section className={` helpSection`}></section>
      <section className={` helpSection`}>
        <div className="max-w-max m-auto flex  flex-col lg:flex-row items-center py-8 px-4 lg:mt-10 lg:py-10 lg:px-28">
          <div className="lg:w-[70%]">
            <h5 className="text-24 font-medium lg:text-36 lg:leading-[48px]">
              How can we help...
            </h5>
            <p className="text-sm mt-6">
              Lorem ipsum dolor sit amet consectetur. Fringilla maecenas diam
              aenean duis sit nunc id integer sed. Egestas dictum elementum
              massa gravida fusce ullamcorper nisl ut non. Viverra et lectus
              velit interdum lectus arcu feugiat. Leo enim odio ut imperdiet
              eget. Dui nunc nec in at amet. Enim tortor diam etiam quam. Amet
              nullam vel faucibus quisque aliquam quis cursus. Purus. Lorem
              ipsum dolor sit amet consectetur. Fringilla maecenas diam aenean
              duis sit nunc id integer sed. Egestas dictum elementum massa
              gravida fusce ullamcorper nisl ut non. Viverra et lectus velit
              interdum lectus arcu feugiat. Leo enim odio ut imperdiet eget. Dui
              nunc nec in at amet. Enim tortor diam etiam quam. Amet nullam vel
              faucibus quisque aliquam quis cursus. Purus.
            </p>
          </div>
          <form
            className="flex flex-col lg:ml-10 lg:w-[30%] mt-10 lg:mt-0"
            action="src/components"
          >
            <h5 className="text-24 lg:text-[40px] lg:leading-[53px] text-center mb-6">
              Contact Us
            </h5>
            <input
              type="text"
              placeholder="Name ..."
              className={"contactInput"}
            />
            <input
              type="email"
              placeholder="Email ..."
              className={"contactInput"}
            />
            <textarea
              className={"contactInput"}
              placeholder="Your Message ..."
              id="w3review"
              name="w3review"
              rows="4"
              cols="50"
            ></textarea>
            <button className={"contactButton"}>SEND</button>
          </form>
        </div>
      </section>
      <section className={` helpSection`}></section>
    </div>
  );
};

export default ContactUsS2C;
