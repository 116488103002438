import React, { useState } from "react";
import CitiesS1B from "../../components/Cities/Boheimian/Cities-S1-B";
import CitiesS2B from "../../components/Cities/Boheimian/Cities-S2-B";
import CitiesS3B from "../../components/Cities/Boheimian/Cities-S3-B";
import ContactUsS1B from "../../components/ContactUs/Boheimian/ContactUs-S1-B";
import ContactUsS2B from "../../components/ContactUs/Boheimian/ContactUs-S2-B";
import ContactUsS3B from "../../components/ContactUs/Boheimian/ContactUs-S3-B";
import FooterS1B from "../../components/Footer/Boheimian/Footer-S1-B";
import FooterS2B from "../../components/Footer/Boheimian/Footer-S2-B";
import FooterS3B from "../../components/Footer/Boheimian/Footer-S3-B";
import HeroCardS1B from "../../components/HeroCard/Boheimian/HearoCard-S1-B";
import HeroCardS2B from "../../components/HeroCard/Boheimian/HearoCard-S2-B";
import HeroCardS3B from "../../components/HeroCard/Boheimian/HearoCard-S3-B";
import ImageOnlyS1B from "../../components/ImageOnly/Boheimian/ImageOnly-S1-B";
import ImageOnlyS2B from "../../components/ImageOnly/Boheimian/ImageOnly-S2-B";
import ImageOnlyS3B from "../../components/ImageOnly/Boheimian/ImageOnly-S3-B";
import TextImageS1B from "../../components/TextImage/Boheimian/TextImage-S1-B";
import TextImageS2B from "../../components/TextImage/Boheimian/TextImage-S2-B";
import TextImageS3B from "../../components/TextImage/Boheimian/TextImage-S3-B";
import TextOnlyS1B from "../../components/TextOnly/Boheimian/TextOnly-S1-B";
import TextOnlyS2B from "../../components/TextOnly/Boheimian/TextOnly-S2-B";
import TextOnlyS3B from "../../components/TextOnly/Boheimian/TextOnly-S3-B";
import Slider from "../../components/Slider";
import { useSearchParams } from "react-router-dom";

const Boheimian = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [componentMemory, setComponentMemory] = useState({
    city: searchParams.get("city") || "CitiesS1B",
    contactUs: searchParams.get("contactUs") || "ContactUsS1B",
    footer: searchParams.get("footer") || "FooterS1B",
    hearoCard: searchParams.get("hearoCard") || "HearoCardS1B",
    textOnly: searchParams.get("textOnly") || "TextOnlyS1B",
    imageOnly: searchParams.get("imageOnly") || "ImageOnlyS1B",
    textImage: searchParams.get("textImage") || "TextImageS1B",
  });

  const updateComponentMemory = (data) => {
    setComponentMemory(data);
    setSearchParams(data);
  };

  return (
    <div className="App flex flex-col gap-20 px-10 pb-10 pt-36">
      <Slider
          currentIndex={1}
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="hearoCard"
        title="HearoCard Components Available"
        components={[HeroCardS1B, HeroCardS2B, HeroCardS3B]}
      />

      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="textImage"
        title="Text Image Components Available"
        components={[TextImageS1B, TextImageS2B, TextImageS3B]}
      />

      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="city"
        title="City Components Available"
        components={[CitiesS1B, CitiesS2B, CitiesS3B]}
      />

      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="textOnly"
        title="Text Only Components Available"
        components={[TextOnlyS1B, TextOnlyS2B, TextOnlyS3B]}
      />

      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="imageOnly"
        title="Image Only Components Available"
        components={[ImageOnlyS1B, ImageOnlyS2B, ImageOnlyS3B]}
      />

      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="contactUs"

        title="Contact Us Components Available"
        components={[ContactUsS1B, ContactUsS2B, ContactUsS3B]}
      />

      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="footer"
        title="Footer Components Available"
        components={[FooterS1B, FooterS2B, FooterS3B]}
      />
    </div>
  );
};

export default Boheimian;
