import React from "react";
import "../ImageOnlyB.css";
import TextOnly from "../../../../media/text-only.png";
const ImageOnlyS2B = () => {
  return (
    <div
      className={`lg:px-[90px] lg:py-[120px] overflow-hidden text-imageOnlyBText ImageOnlyB`}
    >
      <div className={"mt-20 "}>
        <div
          className={`max-w-max lg:mx-auto relative flex h-[400px] lg:h-[700px] p-12  `}
        >
          <img
            className={"w-full h-full absolute inset-0  object-cover"}
            src={TextOnly}
            alt=""
          />

          <h1
            className={
              "z-10 font-medium text-24 leading-[32px] lg:text-40 lg:leading-[53px]"
            }
          >
            Beyond Design.
          </h1>
        </div>
      </div>
    </div>
  );
};

export default ImageOnlyS2B;
