import React from "react";
import "../ImageOnlyB.css";
import TextOnly from "../../../../media/text-only.png";
const ImageOnlyS3B = () => {
  return (
    <div
      className={`lg:px-[90px] lg:py-[120px] overflow-hidden text-imageOnlyBText ImageOnlyB`}
    >
      <div
        className={`max-w-max lg:mx-auto flex flex-col lg:flex-row items-center mt-20 mx-8 `}
      >
        <img
          className={"w-[500px] h-[800px]  inset-0  object-cover"}
          src={TextOnly}
          alt=""
        />
        <h1
          className={
            "mx-auto h-full my-5 font-medium text-24 leading-[32px] lg:text-40 lg:leading-[53px]"
          }
        >
          <span>Beyond</span> <span>Design.</span>{" "}
        </h1>
      </div>
    </div>
  );
};

export default ImageOnlyS3B;
