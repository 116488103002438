import React from "react";
import "../ImageOnlyC.css";
import TextOnly from "../../../../media/text-only.png";
const ImageOnlyS1C = () => {
  return (
    <div
      className={"w-full lg:px-[90px] lg:py-[120px] overflow-hidden ImageOnlyC"}
    >
      <div
        className={`relative max-w-max mx-auto flex justify-center items-center h-[590px] lg:h-[400px] `}
      >
        <img
          className={"w-full h-full absolute inset-0  object-cover"}
          src={TextOnly}
          alt=""
        />

        <h1
          className={
            "z-10 font-medium text-24 leading-[32px] lg:text-40 lg:leading-[53px] tracking-[0.4em]"
          }
        >
          Beyond Design.
        </h1>
      </div>
    </div>
  );
};

export default ImageOnlyS1C;
