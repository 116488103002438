import React from "react";
import StyleCard from "../StyleCard";
import city from "../../media/city/1.png";

const Home = () => {
  return (
    <div className="flex justify-between px-10 gap-5">
      <a className="w-full" href="/boheimian">
        <StyleCard
          styleName="Boheimian Design"
          title="Boheimian Design"
          body="For each widget, we have multiple types. make the combination you like, then copy
           the address and share it with anyone you like. make changes and finally, we are ready 
           to deliver your designed website."
        >
          <img
            className="w-[400px] "
            src={city}
            alt="Boheimian Design"
          />
        </StyleCard>
      </a>
      <a className="w-full" href="/classic">
        <StyleCard
          styleName="Classic Design"
          title="Classic Design"
          body="For each widget, we have multiple types. make the combination you like, then copy
           the address and share it with anyone you like. make changes and finally, we are ready 
           to deliver your designed website."
        >
          <img
            className="w-[400px] "
            src={city}
            alt="Classic Design"
          />
        </StyleCard>
      </a>
      <a className="w-full" href="/modern">
        <StyleCard
          styleName="Modern Design"
          title="Modern Design"
          body="For each widget, we have multiple types. make the combination you like, then copy
           the address and share it with anyone you like. make changes and finally, we are ready 
           to deliver your designed website."
        >
          <img
            className="w-[400px] "
            src={city}
            alt="Modern Design"
          />
        </StyleCard>
      </a>
    </div>
  );
};

export default Home;
