import React from "react";
import "../ImageOnlyB.css";
import TextOnly from "../../../../media/text-only.png";
const ImageOnlyS1B = () => {
  return (
    <div
      className={`lg:px-[90px] lg:py-[120px] overflow-hidden text-imageOnlyBText ImageOnlyB`}
    >
      <div
        className={`max-w-max lg:mx-auto flex flex-col lg:flex-row justify-between items-center`}
      >
        <div className={`w-full lg:w-[70%]`}>
          {/*<img src={image} alt="" className={"w-full  "}/>*/}
          <img
            className={
              "w-full h-full rounded-tr-[400px] rounded-br-[400px]  object-cover"
            }
            src={TextOnly}
            alt=""
          />
        </div>
        <h1
          className={
            "font-medium text-24 leading-[32px] lg:text-40 lg:leading-[53px]"
          }
        >
          Beyond Design.
        </h1>
      </div>
    </div>
  );
};

export default ImageOnlyS1B;
