import React from "react";
import Button from "../Button";

const Slider = (props) => {
  const hasInitialValue = props.componentMemory[props.sliderName];
  const currentIndex = hasInitialValue
    ? props.components.findIndex((c) => c.name === hasInitialValue)
    : 0;

  const nextSlide = () => {
    props.updateComponentMemory({
      ...props.componentMemory,
      [props.sliderName]:
        props.components[(currentIndex + 1) % props.components.length].name,
    });
  };

  const prevSlide = () => {
    props.updateComponentMemory({
      ...props.componentMemory,
      [props.sliderName]:
        props.components[
          (currentIndex - 1 + props.components.length) % props.components.length
        ].name,
    });
  };

  return (
    <div className="border-2 p-5 flex flex-col items-center">
      <h4 className="pb-10 font-sans font-bold text-xl">{props.title}</h4>
      <div className="flex justify-between w-full items-center gap-5">
        <Button onClick={prevSlide}>Previous</Button>
        <div className="p-5 border-2 w-full">
          {props.components.map((Component, index) => (
            <div
              key={index}
              className={`slide ${
                index === currentIndex ? "active" : "hidden"
              }`}
            >
              {index === currentIndex && <Component />}
            </div>
          ))}
        </div>
        <Button onClick={nextSlide}>Next</Button>
      </div>
    </div>
  );
};

export default Slider;
