import React from "react";
import "../ImageOnlyC.css";
import TextOnly from "../../../../media/text-only.png";
const ImageOnlyS3C = () => {
  return (
    <div
      className={"w-full lg:px-[90px] lg:py-[120px] overflow-hidden ImageOnlyC"}
    >
      <div
        className={` relative max-w-max mx-auto flex justify-end items-center mt-20 h-[800px] lg:h-[700px] `}
      >
        <img
          className={"w-full h-full absolute inset-0  object-cover"}
          src={TextOnly}
          alt=""
        />

        <div
          className={
            "bg-white z-10 h-full flex items-end pb-20 mr-10 lg:mr-24 px-5 lg:px-12"
          }
        >
          <h1
            className={
              " flex flex-col lg:flex-row font-medium text-24 leading-[32px] lg:text-40 lg:leading-[53px] tracking-[0.4em]"
            }
          >
            <span>Beyond</span> <span>Design.</span>{" "}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default ImageOnlyS3C;
