import React from "react";
import "../ContactUsB.css";
const ContactUsS2B = () => {
  return (
    <div className="ContactUsB  lg:px-[90px] lg:py-[120px]">
      <section className={` helpSection`}></section>
      <section className={`w-full px-2  helpSection`}>
        <div className="max-w-max m-auto flex  flex-col lg:flex-row items-center py-8 px-4 lg:mt-10 lg:py-10 lg:px-28">
          <form
            className=" lg:w-[40%] mx-auto flex flex-col px-12 mt-10"
            action="src/components"
          >
            <h5 className="text-36 lg:leading-[53px] text-center mb-6">
              Contact Us
            </h5>
            <input
              type="text"
              placeholder="Name ..."
              className={"contactInput"}
            />
            <input
              type="email"
              placeholder="Email ..."
              className={"contactInput"}
            />
            <input
              type="email"
              placeholder="Your Message ..."
              className={"contactInput"}
            />
            <button className={"contactButton"}>SEND</button>
          </form>
        </div>
      </section>
      <section className={` helpSection`}></section>
    </div>
  );
};

export default ContactUsS2B;
