import React from "react";
import "./style.css";
import WaterMartAc01 from "../../../../media/footer/WATERMARK_TheActualized-04 1.png";
const FooterS1M = () => {
  return (
    <div className={"FooterS1M"}>
      <div className="footer-main ">
        <div className="layout relative  flex flex-col lg:flex-row gap-16">
          <div className="footer-section f-nav">
            <div className="footer-title">Quick Access Menu</div>
            <div className="flex justify-between">
              <ul>
                <li>
                  <svg
                    width="6"
                    height="11"
                    viewBox="0 0 6 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.78172 6.14426L4.77957 6.1464L1.32624 9.59973C1.18696 9.73901 0.953125 9.63986 0.953125 9.44662V5.70662V1.55328C0.953125 1.36018 1.1885 1.25935 1.32791 1.39517L3.46624 3.5335L4.77957 4.84684C5.13883 5.2061 5.13573 5.79451 4.78172 6.14426Z"
                      stroke="#363853"
                    />
                  </svg>
                  Home
                </li>
                <li>
                  <svg
                    width="6"
                    height="11"
                    viewBox="0 0 6 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.78172 6.14426L4.77957 6.1464L1.32624 9.59973C1.18696 9.73901 0.953125 9.63986 0.953125 9.44662V5.70662V1.55328C0.953125 1.36018 1.1885 1.25935 1.32791 1.39517L3.46624 3.5335L4.77957 4.84684C5.13883 5.2061 5.13573 5.79451 4.78172 6.14426Z"
                      stroke="#363853"
                    />
                  </svg>
                  Projects
                </li>
                <li>
                  <svg
                    width="6"
                    height="11"
                    viewBox="0 0 6 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.78172 6.14426L4.77957 6.1464L1.32624 9.59973C1.18696 9.73901 0.953125 9.63986 0.953125 9.44662V5.70662V1.55328C0.953125 1.36018 1.1885 1.25935 1.32791 1.39517L3.46624 3.5335L4.77957 4.84684C5.13883 5.2061 5.13573 5.79451 4.78172 6.14426Z"
                      stroke="#363853"
                    />
                  </svg>
                  Contact Us
                </li>
                <li>
                  <svg
                    width="6"
                    height="11"
                    viewBox="0 0 6 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.78172 6.14426L4.77957 6.1464L1.32624 9.59973C1.18696 9.73901 0.953125 9.63986 0.953125 9.44662V5.70662V1.55328C0.953125 1.36018 1.1885 1.25935 1.32791 1.39517L3.46624 3.5335L4.77957 4.84684C5.13883 5.2061 5.13573 5.79451 4.78172 6.14426Z"
                      stroke="#363853"
                    />
                  </svg>
                  About Us
                </li>
                <li>
                  <svg
                    width="6"
                    height="11"
                    viewBox="0 0 6 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.78172 6.14426L4.77957 6.1464L1.32624 9.59973C1.18696 9.73901 0.953125 9.63986 0.953125 9.44662V5.70662V1.55328C0.953125 1.36018 1.1885 1.25935 1.32791 1.39517L3.46624 3.5335L4.77957 4.84684C5.13883 5.2061 5.13573 5.79451 4.78172 6.14426Z"
                      stroke="#363853"
                    />
                  </svg>
                  Soultions
                </li>
              </ul>
              <ul>
                <li>
                  <svg
                    width="6"
                    height="11"
                    viewBox="0 0 6 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.78172 6.14426L4.77957 6.1464L1.32624 9.59973C1.18696 9.73901 0.953125 9.63986 0.953125 9.44662V5.70662V1.55328C0.953125 1.36018 1.1885 1.25935 1.32791 1.39517L3.46624 3.5335L4.77957 4.84684C5.13883 5.2061 5.13573 5.79451 4.78172 6.14426Z"
                      stroke="#363853"
                    />
                  </svg>
                  Home
                </li>
                <li>
                  <svg
                    width="6"
                    height="11"
                    viewBox="0 0 6 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.78172 6.14426L4.77957 6.1464L1.32624 9.59973C1.18696 9.73901 0.953125 9.63986 0.953125 9.44662V5.70662V1.55328C0.953125 1.36018 1.1885 1.25935 1.32791 1.39517L3.46624 3.5335L4.77957 4.84684C5.13883 5.2061 5.13573 5.79451 4.78172 6.14426Z"
                      stroke="#363853"
                    />
                  </svg>
                  Projects
                </li>
                <li>
                  <svg
                    width="6"
                    height="11"
                    viewBox="0 0 6 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.78172 6.14426L4.77957 6.1464L1.32624 9.59973C1.18696 9.73901 0.953125 9.63986 0.953125 9.44662V5.70662V1.55328C0.953125 1.36018 1.1885 1.25935 1.32791 1.39517L3.46624 3.5335L4.77957 4.84684C5.13883 5.2061 5.13573 5.79451 4.78172 6.14426Z"
                      stroke="#363853"
                    />
                  </svg>
                  Contact Us
                </li>
                <li>
                  <svg
                    width="6"
                    height="11"
                    viewBox="0 0 6 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.78172 6.14426L4.77957 6.1464L1.32624 9.59973C1.18696 9.73901 0.953125 9.63986 0.953125 9.44662V5.70662V1.55328C0.953125 1.36018 1.1885 1.25935 1.32791 1.39517L3.46624 3.5335L4.77957 4.84684C5.13883 5.2061 5.13573 5.79451 4.78172 6.14426Z"
                      stroke="#363853"
                    />
                  </svg>
                  About Us
                </li>
                <li>
                  <svg
                    width="6"
                    height="11"
                    viewBox="0 0 6 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.78172 6.14426L4.77957 6.1464L1.32624 9.59973C1.18696 9.73901 0.953125 9.63986 0.953125 9.44662V5.70662V1.55328C0.953125 1.36018 1.1885 1.25935 1.32791 1.39517L3.46624 3.5335L4.77957 4.84684C5.13883 5.2061 5.13573 5.79451 4.78172 6.14426Z"
                      stroke="#363853"
                    />
                  </svg>
                  Soultions
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-section">
            <div className="footer-title">About</div>
            <div className="flex ">
              Lorem ipsum dolor sit amet consectetur. Fringilla maecenas diam
              aenean duis sit nunc id integer sed. Egestas dictum elementum
              massa gravida fusce ullamcorper nisl ut non. Viverra et lectus
              velit interdum lectus arcu feugiat. Leo enim odio ut imperdiet
              eget. Dui nunc nec in at amet. Enim tortor diam etiam quam. Amet
              nullam vel faucibus quisque aliquam quis cursus. Purus.
            </div>
          </div>
          <div className="footer-section lg:mr-48">
            <div className="footer-title">Contact</div>
            <div className="flex flex-col gap-6">
              <div className=" ">
                <div className={"text-16 roboto"}>Phone Number</div>
                <div className={"text-20"}>+1 443 456 1220</div>
              </div>
              <div className="">
                <div className={"text-16 roboto"}>Email</div>
                <div className={"text-20 underli "}>info@TheActualized.com</div>
              </div>
            </div>
          </div>

          <div className="logo lg:-bottom-32 right-0 lg:absolute aspect-square bg-[#9DA6FA] rounded-8 p-5 min-w-[150px] w-40 mx-auto">
            <img
              className={"w-full h-full object-top object-contain"}
              src={WaterMartAc01}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="footer-bottom  pb-8 pt-4">
        Designed by TheActulized.com
      </div>
    </div>
  );
};

export default FooterS1M;
