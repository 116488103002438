import React, { useState } from "react";
import CardViewS1C from "../../components/CardViews/Classic/CardView-S1-C";
import CardViewS2C from "../../components/CardViews/Classic/Cardview-S2-C";
import CardViewS3C from "../../components/CardViews/Classic/Cardview-S3-C";
import CitiesS1C from "../../components/Cities/Classic/Cities-S1-C";
import CitiesS2C from "../../components/Cities/Classic/Cities-S2-C";
import CitiesS3C from "../../components/Cities/Classic/Cities-S3-C";
import ContactUsS1C from "../../components/ContactUs/Classic/ContactUs-S1-C";
import ContactUsS2C from "../../components/ContactUs/Classic/ContactUs-S2-C";
import ContactUsS3C from "../../components/ContactUs/Classic/ContactUs-S3-C";
import FooterS1C from "../../components/Footer/Classic/Footer-S1-C";
import FooterS2C from "../../components/Footer/Classic/Footer-S2-C";
import FooterS3C from "../../components/Footer/Classic/Footer-S3-C";
import HeaderS11C from "../../components/Header/Classic/Header-S1-1-C";
import HeaderS12C from "../../components/Header/Classic/Header-S1-2-C";
import HeaderS13C from "../../components/Header/Classic/Header-S1-3-C";
import HeaderS2C from "../../components/Header/Classic/Header-S2-C";
import HeaderS3C from "../../components/Header/Classic/Header-S3-C";
import HeroCardS1C from "../../components/HeroCard/Classic/HearoCard-S1-C";
import HeroCardS2C from "../../components/HeroCard/Classic/HearoCard-S2-C";
import HeroCardS3C from "../../components/HeroCard/Classic/HearoCard-S3-C";
import ImageOnlyS1C from "../../components/ImageOnly/Classic/ImageOnly-S1-C";
import ImageOnlyS2C from "../../components/ImageOnly/Classic/ImageOnly-S2-C";
import ImageOnlyS3C from "../../components/ImageOnly/Classic/ImageOnly-S3-C";
import InstagramS1C from "../../components/Instagram/Classic/Instagram-S1-C";
import InstagramS2C from "../../components/Instagram/Classic/Instagram-S2-C";
import InstagramS3C from "../../components/Instagram/Classic/Instagram-S3-C";
import TextImageS1C from "../../components/TextImage/Classic/TextImage-S1-C";
import TextImageS2C from "../../components/TextImage/Classic/TextImage-S2-C";
import TextImageS3C from "../../components/TextImage/Classic/TextImage-S3-C";
import TextOnlyS1C from "../../components/TextOnly/Classic/TextOnly-S1-C";
import TextOnlyS2C from "../../components/TextOnly/Classic/TextOnly-S2-C";
import TextOnlyS3C from "../../components/TextOnly/Classic/TextOnly-S3-C";
import Slider from "../../components/Slider";
import { useSearchParams } from "react-router-dom";

const Classic = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [componentMemory, setComponentMemory] = useState({
    cardView: searchParams.get("cardView") || "CardViewS1C",
    city: searchParams.get("city") || "CitiesS1C",
    contactUs: searchParams.get("contactUs") || "ContactUsS1C",
    footer: searchParams.get("footer") || "FooterS1C",
    header: searchParams.get("header") || "HeaderS11C",
    hearoCard: searchParams.get("hearoCard") || "HearoCardS1C",
    textOnly: searchParams.get("textOnly") || "TextOnlyS1C",
    instagram: searchParams.get("instagram") || "InstagramS1C",
    imageOnly: searchParams.get("imageOnly") || "ImageOnlyS1C",
    textImage: searchParams.get("textImage") || "TextImageS1C",
  });

  const updateComponentMemory = (data) => {
    setComponentMemory(data);
    setSearchParams(data);
  };

  return (
    <div className="App flex flex-col gap-20 px-10 pb-10 pt-36">
      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="header"
        title="Header Components Available"
        components={[HeaderS11C, HeaderS12C, HeaderS13C, HeaderS2C, HeaderS3C]}
      />

      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="hearoCard"
        title="Hearo Card Components Available"
        components={[HeroCardS1C, HeroCardS2C, HeroCardS3C]}
      />

      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="cardView"
        title="Card View Components Available"
        components={[CardViewS1C, CardViewS2C, CardViewS3C]}
      />

      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="textImage"
        title="Text Image Components Available"
        components={[TextImageS1C, TextImageS2C, TextImageS3C]}
      />

      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="city"
        title="City Components Available"
        components={[CitiesS1C, CitiesS2C, CitiesS3C]}
      />

      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="textOnly"
        title="Text Only Components Available"
        components={[TextOnlyS1C, TextOnlyS2C, TextOnlyS3C]}
      />

      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="imageOnly"
        title="Image Only Components Available"
        components={[ImageOnlyS1C, ImageOnlyS2C, ImageOnlyS3C]}
      />

      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="instagram"
        title="Instagram Components Available"
        components={[InstagramS1C, InstagramS2C, InstagramS3C]}
      />

      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="contactUs"
        title="Contact Us Components Available"
        components={[ContactUsS1C, ContactUsS2C, ContactUsS3C]}
      />

      <Slider
        componentMemory={componentMemory}
        updateComponentMemory={updateComponentMemory}
        sliderName="footer"
        title="Footer Components Available"
        components={[FooterS1C, FooterS2C, FooterS3C]}
      />
    </div>
  );
};

export default Classic;
