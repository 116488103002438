import React from "react";
import "../ImageOnlyC.css";
import TextOnly from "../../../../media/text-only.png";
const ImageOnlyS2C = () => {
  return (
    <div
      className={"w-full lg:px-[90px] lg:py-[120px] overflow-hidden ImageOnlyC"}
    >
      <div className={"  relative mt-20 h-[550px] "}>
        <div className={`relative z-10 w-full max-w-max mx-auto  h-full `}>
          <div
            className={`relative h-full lg:w-[70%] mr-auto left-0 flex  items-end p-12 `}
          >
            <img
              className={"w-full h-full absolute inset-0  object-cover"}
              src={TextOnly}
              alt=""
            />
            <h1
              className={
                "z-10 font-medium text-24 leading-[32px] lg:text-40 lg:leading-[53px] tracking-[0.4em]"
              }
            >
              Beyond Design.
            </h1>
          </div>
        </div>
        <div
          className={
            "absolute w-full h-[110%]  border-dark Border border-1 -top-6 inset-x-1/3"
          }
        ></div>
      </div>
    </div>
  );
};

export default ImageOnlyS2C;
