import React from "react";

const Button = (props) => {
  return (
    <button
      className="border-2 rounded-lg hover:bg-blue-600 border-blue-600 w-36 h-10 font-sans font-bold text-blue-600 hover:text-white"
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default Button;
